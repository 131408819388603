export function orderStatusName(status) {
  switch (status) {
    case "waiting":
      return "未付款";
    case "confirming":
      return "留單";
    case "deposit_paid":
      return "已付訂金";
    case "in_production":
      return "製作中";
    case "in_inspection":
      return "整理檢查";
    case "store_pickup":
      return "可店取";
    case "delivered":
      return "已寄送";
    case "completed":
      return "已完成";
    default:
      status;
  }
}

export function repairOrderStatusName(status) {
  switch (status) {
    case "waiting":
      return "等待中";
    case "accepted":
      return "已受理";
    case "in_maintenance":
      return "維修中";
    case "in_inspection":
      return "檢查中";
    case "completed":
      return "已完成";
    default:
      status;
  }
}

export function factoryOrderStatusName(status) {
  switch (status) {
    case "pending":
      return "等待中";
    case "in_production":
      return "製作中";
    case "arrival":
      return "已到達";
    case "in_inspection":
      return "整貨中";
    case "complete":
      return "已完成";
  }
}
