import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { validateMobileNumber } from "../../../src/Utils/FormUtil";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../../Components/Widget";
import SearchOrderItem from "../../Components/BuffetSearchOrderItem";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

const UiState = {
  search: "search",
  result: "result"
};

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.search,
      inputValue: "",
      commonOrders: null,
      repairOrders: null,
      showSpinner: false,
      hint: ""
    };
  }

  render() {
    let {
      uiState,
      inputValue,
      commonOrders,
      repairOrders,
      showSpinner,
      hint
    } = this.state;

    return (
      <Wrapper uiState={uiState}>
        <div className="top-container" />
        <div className="content-container">
          <div className="title">BUFFET</div>

          {(() => {
            if (uiState === UiState.result) {
              return (
                <>
                  <div
                    className="button"
                    style={{ marginBottom: 35 }}
                    onClick={() =>
                      this.setState({ uiState: UiState.search, inputValue: "" })
                    }
                  >
                    返回
                  </div>
                  <div className="items-container">
                    {commonOrders.length === 0 && repairOrders.length === 0 && (
                      <div className="text" style={{ textAlign: "center" }}>
                        您目前沒有任何訂單與維修單！
                      </div>
                    )}
                    {commonOrders &&
                      commonOrders.length > 0 &&
                      commonOrders
                        .sort((a, b) => b.id - a.id)
                        .map((order, i) => (
                          <SearchOrderItem
                            key={"common-order" + i}
                            type="common-order"
                            data={order}
                            css="margin-bottom: 50px;"
                          />
                        ))}
                    {repairOrders &&
                      repairOrders.length > 0 &&
                      repairOrders
                        .sort((a, b) => b.id - a.id)
                        .map((order, i) => (
                          <SearchOrderItem
                            key={"repair-order" + i}
                            type="repair-order"
                            data={order}
                            css="margin-bottom: 50px;"
                          />
                        ))}
                  </div>
                  <div className="contact">
                    {`若有任何疑問，歡迎撥打(02)-2798-9384\n我們會有專人為您服務`}
                  </div>
                </>
              );
            }

            return (
              <>
                <div className="description">{`請輸入您的手機號碼\n查看訂單及維修單`}</div>
                <input
                  placeholder="ex. 0900000000"
                  autoFocus
                  value={inputValue}
                  onChange={e =>
                    this.setState({ inputValue: e.target.value, hint: "" })
                  }
                />
                {hint && <div className="hint">{hint}</div>}
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div
                    className="button"
                    onClick={() => this._submit(inputValue)}
                  >
                    送出
                  </div>
                )}
              </>
            );
          })()}
        </div>
      </Wrapper>
    );
  }

  _submit = value => {
    let { appActions } = this.props;

    if (value === "") {
      this.setState({ hint: "請輸入手機號碼！" });
      return;
    } else if (validateMobileNumber(value) === false) {
      this.setState({ hint: "手機號碼格式錯誤！" });
      return;
    }

    this.setState({ showSpinner: true });
    delay(100)
      .then(() => appActions.fetchCommonOrdersByPhone(value))
      .then(result => this.setState({ commonOrders: result }))
      .then(() => appActions.fetchRepairOrdersByPhone(value))
      .then(result => this.setState({ repairOrders: result }))
      .then(() => this.setState({ uiState: UiState.result }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background: #f9f9f9;
  position: relative;

  & .top-container {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 70px;
    background: #8596a6;
    padding: 10px 15px;
  }

  & .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 70px;
    padding: 10px;
    padding-top: ${props => (props.uiState === "result" ? "60" : " 10")}px;
    max-width: 320px;
    width: 100%;
    min-height: calc(100vh - 70px);
  }

  & .items-container {
    ${props =>
      props.uiState === "result"
        ? `
          padding-top: 150px;
          min-height: calc(100vh - 328px);
        `
        : ``};
    margin-bottom: 20px;
    max-width: 260px;
    width: 100%;
  }

  & .title {
    ${props =>
      props.uiState === "result"
        ? `
          position: absolute;
          top: 130px;
          background: #f9f9f9;
        `
        : ``};
    margin-bottom: 20px;
    letter-spacing: 5px;
    font-weight: 300;
    font-size: 28px;
  }

  & .description {
    margin-bottom: 60px;
    letter-spacing: 5px;
    white-space: pre-wrap;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
  }

  & input {
    margin-bottom: 50px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    max-width: 300px;
    width: 100%;
    height: 40px;

    text-align: center;
    letter-spacing: 3px;
    font-weight: 300;
    font-size: 15px;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: lightgray;
    }
  }

  & .hint {
    margin-bottom: 20px;
    letter-spacing: 3px;
    font-weight: 300;
    font-size: 15px;
    color: #c80f26;
  }

  & .button {
    ${props =>
      props.uiState === "result"
        ? `
          position: absolute;
          top: 190px;
          background: #f9f9f9;
        `
        : ``};

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100px;
    width: 100%;
    height: 40px;
    letter-spacing: 1.6px;

    cursor: pointer;
    border: 1px solid #8596a6;
    background: #ffffff;
    letter-spacing: 1.6px;
    font-size: 15px;
    color: #8596a6;
  }

  & .text {
    font-weight: 300;
    font-size: 15px;
  }

  & .contact {
    padding-bottom: 15px;
    white-space: pre-wrap;
    text-align: center;
    font-weight: 300;
    font-size: 15px;
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(SearchPage)
);
