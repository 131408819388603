import React, { Component } from "react";
import styled from "styled-components";
import {
  commonOrderNumberForamt,
  repairOrderNumberFormat
} from "../../src/Utils/NumberingFormatUtil";
import {
  orderStatusName,
  repairOrderStatusName
} from "../../src/Utils/OrderStatusNameUtil";

class SearchOrderItem extends Component {
  render() {
    let { type, data, css } = this.props;

    if (type === "common-order") {
      return (
        <Wrapper css={css}>
          <div className="text">訂單編號：{commonOrderNumberForamt(data)}</div>
          <div className="text">訂購人姓名：{data.buyer_name}</div>
          <div className="text">訂購人電郵：{data.buyer_email}</div>
          <div className="text">訂購人手機：{data.buyer_phone}</div>
          <div className="text">寄送地址：{data.receiver_address}</div>
          <div className="text">
            訂單狀態：
            <span className="highlight">{orderStatusName(data.status)}</span>
          </div>
        </Wrapper>
      );
    }

    return (
      //repair order
      <Wrapper css={css}>
        <div className="text">維修單編號：{repairOrderNumberFormat(data)}</div>
        <div className="text">訂購人姓名：{data.buyer_name}</div>
        <div className="text">訂購人電郵：{data.buyer_email}</div>
        <div className="text">訂購人手機：{data.buyer_phone}</div>
        <div className="text">寄送地址：{data.receiver_address}</div>
        <div className="text">
          訂單狀態：
          <span className="highlight">
            {repairOrderStatusName(data.status)}
          </span>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;

  & .text {
    margin-bottom: 10px;
    word-break: break-all;
  }

  & .highlight {
    font-weight: 500;
    color: #8596a6;
  }

  ${props => props.css || ""};
`;

export default SearchOrderItem;
