export function staffNumberFormat(id) {
  if (id.toString().length === 1) {
    return "00" + id;
  } else if (id.toString().length === 2) {
    return "0" + id;
  } else {
    return id.toString();
  }
}

export function commonOrderNumberForamt(data) {
  let id = data.id;
  let created = new Date(data.created);
  let year = created
    .getFullYear()
    .toString()
    .slice(-2);
  let month = ("00" + (created.getMonth() + 1)).toString().slice(-2);

  let idIn6Digits = ("000000" + id.toString()).slice(-6);
  return `C${year}${month}${idIn6Digits}`;
}

export function repairOrderNumberFormat(data) {
  let id = data.id;
  let today = new Date();
  let year = today
    .getFullYear()
    .toString()
    .slice(-2);
  let month = ("00" + (today.getMonth() + 1)).toString().slice(-2);
  let idIn6Digits = ("000000" + id.toString()).slice(-6);

  return `R${year}${month}${idIn6Digits}`;
}

export function factoryOrderNumberFormat(data) {
  let id = data.id;
  let today = new Date();
  let year = today
    .getFullYear()
    .toString()
    .slice(-2);
  let month = ("00" + (today.getMonth() + 1)).toString().slice(-2);
  let idIn6Digits = ("000000" + id.toString()).slice(-6);

  return `F${year}${month}${idIn6Digits}`;
}
